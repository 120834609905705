<mat-toolbar>
  <a href="/">
    <img alt="" height="274" id="main-logo" ngSrc="/assets/EIT_logo.png" priority width="911"/>
  </a>

  <div>
    <button mat-icon-button matTooltip="{{'chooseLanguage' | translate}}" color="primary"
            [matMenuTriggerFor]="languageMenu">
      <mat-icon>language</mat-icon>
    </button>
    <mat-menu #languageMenu="matMenu">
      @for (language of translate.langs; track language) {
        <button mat-menu-item (click)="useLanguage(language)">
          <div class="row">
            <span>{{ language.toUpperCase() }}</span>
            @if (translate.currentLang === language) {
              <mat-icon color="primary">check</mat-icon>
            }
          </div>
        </button>
      }
    </mat-menu>

    <button mat-icon-button matTooltip="{{ username$ | async}}" color="primary"
            [matMenuTriggerFor]="(isAdmin$ | async) ? menu : null">
      <mat-icon>person</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item disabled>
        <span>{{ username$ | async }}</span>
      </button>
      <button mat-menu-item routerLink="/admin">
        {{ 'adminPage.title' | translate }}
      </button>
    </mat-menu>
    <ng-container *ngIf="(notificationsCount$ | async); let notifCount">
      <button mat-icon-button color="{{notifCount > 0 ? 'warn' : 'primary'}}" [routerLink]="['/notifications']">
        <mat-icon [matBadge]="notifCount > 0 ? notifCount : null" matBadgeSize="small"
                  [matBadgeColor]="notifCount > 0 ? 'warn' : 'primary'">{{ notifCount > 0 ? 'notifications_active' : 'notifications_none' }}
        </mat-icon>
      </button>
    </ng-container>

    <button mat-icon-button matTooltip="{{'logOut' | translate}}" color="primary" (click)="logOut()">
      <mat-icon>exit_to_app</mat-icon>
    </button>
  </div>
</mat-toolbar>
