import {ApplicationConfig, DEFAULT_CURRENCY_CODE, importProvidersFrom, LOCALE_ID} from '@angular/core';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {provideRouter, withComponentInputBinding} from '@angular/router';
import {routes} from './app.routes';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import {provideOAuthClient} from 'angular-oauth2-oidc';
import {environment} from "../environments/environment";
import {UserState} from "./state/user.state";
import {NgxsModule} from "@ngxs/store";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {MAT_DATE_LOCALE} from "@angular/material/core";
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {DATE_PIPE_DEFAULT_OPTIONS, registerLocaleData} from '@angular/common';
import {provideLuxonDateAdapter} from "@angular/material-luxon-adapter";
import {Settings} from "luxon";
import {MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar';
import {NotificationState} from "./state/notification.state";
import {provideEnvironmentNgxMask} from "ngx-mask";
import localeFrBe from '@angular/common/locales/fr-BE';
import localeFrNl from '@angular/common/locales/nl-BE';
import {ApiConfiguration} from "../api/api-configuration";

// Luxon configuration
Settings.defaultZone = "Europe/Brussels";
registerLocaleData(localeFrBe);
registerLocaleData(localeFrNl);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-BE' },
    provideRouter(routes, withComponentInputBinding()),
    provideOAuthClient({
      resourceServer: {
        allowedUrls: [environment.apiUrl],
        sendAccessToken: true
      }
    }),
    importProvidersFrom(
      NgxsModule.forRoot([UserState, NotificationState], {
        developmentMode: !environment.production
      }),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      })
    ),
    provideHttpClient(withInterceptorsFromDi()),
    provideLuxonDateAdapter(),
    provideAnimationsAsync(),
    provideEnvironmentNgxMask(),
    {
      provide: ApiConfiguration,
      useFactory: () => configureApiService(),
    },
    {provide: MAT_DATE_LOCALE, useValue: 'fr-BE'},
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'},
    {provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: {dateFormat: 'dd/MM/yyyy'}},
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}},
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 5000}}
  ]
};

function configureApiService() {
  const apiConfiguration = new ApiConfiguration();
  apiConfiguration.rootUrl = environment.apiUrl;
  return apiConfiguration;
}
