import {WebsocketService} from "../websocket.service";
import {inject, Injectable} from "@angular/core";
import {User} from "../../models/user";
import {from, mergeMap, Observable} from "rxjs";
import {EventType} from "../../models/event-type";
import {filter, map} from "rxjs/operators";
import {UserRole} from "../../models/user-role";
import {Notification} from "../../models/notification";

interface WsEventDto {
  type: EventType;
  role: UserRole;
  purchaseOrderUuid: string;
}

@Injectable({ providedIn: 'root' })
export class RoleTopicService {
  private websocketService = inject(WebsocketService);
  createRoleTopic(user: User): Observable<Notification> {
    if (!user || !user.roles) {
      throw new Error('a user with roles is required');
    }

    return from(user.roles).pipe(
      filter(role => role === 'DIRECTOR' || role === 'QHSE'), // At the moment, only subscribing for those two roles
      map(role => `/digiproc/topic/role/${role}`),
      mergeMap(topic => this.websocketService.createTopic<Notification>(topic, {observe: 'body'}))
    );
  }
}
