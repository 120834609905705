import {inject} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngxs/store';
import {filter, map, mergeMap} from 'rxjs/operators';
import {UserState} from './state/user.state';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest} from "rxjs";

export const isAdmin = () => {
  const router = inject(Router);
  const store = inject(Store);
  return store.select(UserState.currentUser)
    .pipe(
      filter(user => !!user),
      mergeMap(() => store.select(UserState.isAdmin)),
      map(isAdmin => {
        if (isAdmin) {
          return true;
        }
        return router.createUrlTree(['/']);
      })
    );
};

export const isDirectorWithRedirect = () => {
  const translateService = inject(TranslateService);
  const snackBar = inject(MatSnackBar);
  const router = inject(Router);
  const store = inject(Store);
  return store.select(UserState.currentUser)
    .pipe(
      filter(user => !!user),
      mergeMap(() => store.select(UserState.isDirector)),
      map(isDirector => {
        if (isDirector) {
          return true;
        }
        snackBar.open(translateService.instant('noDirectorRoleSnackbar'));
        return router.createUrlTree(['/']);
      })
    );
};

export const isQhseWithRedirect = () => {
  const translateService = inject(TranslateService);
  const snackBar = inject(MatSnackBar);
  const router = inject(Router);
  const store = inject(Store);
  return store.select(UserState.currentUser)
    .pipe(
      filter(user => !!user),
      mergeMap(() => store.select(UserState.isQhse)),
      map(isQhse => {
        if (isQhse) {
          return true;
        }
        snackBar.open(translateService.instant('noQhseRoleSnackbar'));
        return router.createUrlTree(['/']);
      })
    );
};

export const isAccountant = () => {
  const translateService = inject(TranslateService);
  const snackBar = inject(MatSnackBar);
  const router = inject(Router);
  const store = inject(Store);
  return store.select(UserState.currentUser)
    .pipe(
      filter(user => !!user),
      mergeMap(() => store.select(UserState.isAccountant)),
      map(isAccountant => {
        if (isAccountant) {
          return true;
        }
        snackBar.open(translateService.instant('noAccountantRoleSnackbar'));
        return router.createUrlTree(['/']);
      })
    );
};

export const isQhse = () => {
  const store = inject(Store);
  return store.select(UserState.currentUser)
    .pipe(
      filter(user => !!user),
      mergeMap(() => store.select(UserState.isQhse)),
      map(isQhse => {
        return isQhse;
      })
    );
}

export const isDirector = () => {
  const store = inject(Store);
  return store.select(UserState.currentUser)
    .pipe(
      filter(user => !!user),
      mergeMap(() => store.select(UserState.isDirector)),
      map(isDirector => {
        return isDirector;
      })
    );
}

export const isQhseOrDirector = () => {
  return combineLatest([isQhse(), isDirector()]).pipe(map(([qhse, director]) => qhse || director));
}
