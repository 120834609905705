import {AsyncPipe, NgIf, NgOptimizedImage} from '@angular/common';
import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {MatIconButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {MatToolbar} from '@angular/material/toolbar';
import {MatTooltip} from '@angular/material/tooltip';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {OAuthService} from 'angular-oauth2-oidc';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {Router, RouterLink} from '@angular/router';
import {Store} from '@ngxs/store';
import {UserState} from '../state/user.state';
import {Observable} from 'rxjs';
import {MatSuffix} from '@angular/material/form-field';
import {MatBadge} from "@angular/material/badge";
import {NotificationState} from "../state/notification.state";
import {filter, map} from "rxjs/operators";
import {NotificationActions} from "../state/euroports.actions";

@Component({
  selector: 'euroports-header',
  standalone: true,
  imports: [
    MatIcon,
    MatIconButton,
    MatToolbar,
    MatTooltip,
    TranslateModule,
    NgOptimizedImage,
    MatMenu,
    MatMenuTrigger,
    MatMenuItem,
    RouterLink,
    AsyncPipe,
    MatSuffix,
    AsyncPipe,
    MatBadge,
    NgIf
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  isAdmin$: Observable<boolean> = inject(Store).select(UserState.isAdmin);
  username$ = inject(Store).select(UserState.currentUser).pipe(filter(user => !!user), map(user => user.firstName + " " + user.lastName));
  private oauthService = inject(OAuthService);
  public translate = inject(TranslateService);
  public router = inject(Router);
  private store = inject(Store);

  public notificationsCount$: Observable<number>;

  constructor() {
    this.notificationsCount$ = this.store.select(NotificationState.count).pipe(filter(value => typeof value == 'number'));
    this.store.dispatch(new NotificationActions.Load());
  }

  ngOnInit(): void {
  }

  useLanguage(language: string): void {
    this.translate.use(language);
  }

  logOut() {
    this.oauthService.logOut();
  }
}
